import styled from "@emotion/styled";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { breakpoints, rem, theme } from "../styling/theme";
import Cover from "../ui/Cover";
import FlexBox from "../ui/FlexBox";
import SectionText from "../ui/SectionText";
import { getCoverUrl } from "../utils/helpers";
import { CollectionAttributes, SectionT } from "../utils/types";
import BlockWrapper from "./BlockWrapper";
import GridItemsBlock from "./content-blocks/GridItemsBlock";
import LoopBlock from "./content-blocks/LoopBlock";
import RenderBlock from "./content-blocks/RenderBlock";
import TextBlock from "./content-blocks/TextBlock";
import VimeoBlock from "./content-blocks/VimeoBlock";

const TitleWrapper = styled(FlexBox)<{ isActive: boolean }>(({ isActive }) => ({
  position: "absolute",
  transform: `rotate(${isActive ? 0 : -90}deg)`,
  justifyContent: "flex-start",
  transformOrigin: "left",
  width: "100vh",
  bottom: isActive ? "50vh" : rem(20),
  left: isActive ? rem(40) : `calc(100vw - ${rem(80)})`,
  transition: "all 2s cubic-bezier(0.22, 1, 0.36, 1)",

  [theme.media.maxMd]: {
    maxWidth: isActive ? "85vw" : "none",
    left: isActive ? rem(30) : `calc(100vw - ${rem(50)})`,
  },

  [theme.media.maxSm]: {
    left: isActive ? rem(20) : `calc(100vw - ${rem(50)})`,
  },
}));

const StyledText = styled(SectionText)({
  position: "relative",
  left: "auto",
  top: "auto",
  fontWeight: 500,
});

const Title = styled(StyledText)({
  fontSize: rem(44),
  lineHeight: rem(45),

  [theme.media.maxMd]: {
    fontSize: rem(35),
    lineHeight: rem(30),
  },
});

const SubTitle = styled(StyledText)({
  fontSize: rem(24),
  color: theme.color.whiteColor,
});

type SectionPropsT = {
  item: CollectionAttributes<SectionT>;
  isActive: boolean;
  setActiveSection: Dispatch<SetStateAction<number | null>>;
  forceScrollToSection: boolean;
};

const Section: FC<SectionPropsT> = ({
  item,
  isActive,
  setActiveSection,
  forceScrollToSection,
}) => {
  const {
    id,
    attributes: { cover, title, subtitle, content_blocks, slug },
  } = item;

  const { tabletVersion, mobileVersion } = useResponsive(breakpoints.sm);
  const [activePending, setActivePending] = useState(false);

  const setActivePendingHandle = () => {
    setActivePending(true);
    setTimeout(() => {
      if (!isActive) {
        setActivePending(false);
      }
    }, 1000);
  };

  const setActiveSectionHandle = () => {
    setActiveSection(id);

    if (slug) {
      window.history.pushState("", "", `?${slug}`);
    }
  };

  return (
    <BlockWrapper
      isActive={isActive}
      activePending={activePending}
      setActivePending={setActivePending}
      setActiveSectionHandle={setActiveSectionHandle}
      forceScrollToSection={forceScrollToSection}
      cover={
        <Cover
          url={getCoverUrl(
            tabletVersion || mobileVersion,
            cover.desktop,
            cover.mobile
          )}
          onClick={!isActive ? setActivePendingHandle : undefined}
        >
          <TitleWrapper
            isActive={isActive}
            alignItems="flex-start"
            flexDirection="column"
          >
            <Title element="h2">{title}</Title>
            <SubTitle element="h3">{subtitle}</SubTitle>
          </TitleWrapper>
        </Cover>
      }
    >
      {isActive && (
        <FlexBox flexDirection="column">
          {content_blocks.map((item, index) => {
            const { __component: type } = item;
            const hideSections = tabletVersion || mobileVersion;

            if (type === "molecule.content-render" && !hideSections) {
              return <RenderBlock key={index} data={item} />;
            }

            if (type === "molecule.content-loop" && !hideSections) {
              if (item.media.data) {
                return <LoopBlock key={index} data={item} />;
              }
            }

            if (type === "molecule.content-text" && !hideSections) {
              return <TextBlock key={index} data={item} />;
            }

            if (type === "molecule.content-vimeo") {
              return <VimeoBlock key={index} data={item} />;
            }

            if (type === "molecule.content-grid-media" && !hideSections) {
              const { items, options } = item;

              return (
                <GridItemsBlock
                  key={index}
                  items={items}
                  backgroundColor={options.background_color}
                  fullHeight={options.full_height}
                  lightBoxOption={true}
                />
              );
            }
          })}
        </FlexBox>
      )}
    </BlockWrapper>
  );
};

export default Section;
