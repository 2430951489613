import styled from "@emotion/styled";
import Vimeo from "@u-wave/react-vimeo";
import React, { FC } from "react";
import { rem } from "../../styling/theme";
import ContentBlockWrapper from "../../ui/ContentBlockWrapper";
import { SectionVimeoT } from "../../utils/types";

const Video = styled(Vimeo)({
  padding: rem(50),
  width: "100%",
  height: "100%",
  maxWidth: rem(1500),
  minHeight: rem(800),

  ["iframe"]: {
    width: "100%",
    height: "100%",
  },
});

type VimeoBlockT = {
  data: SectionVimeoT;
};

const VimeoBlock: FC<VimeoBlockT> = ({ data }) => {
  const {
    options: { background_color, full_height },
    vimeo_url,
  } = data;

  return (
    <ContentBlockWrapper
      fullHeight={full_height}
      backgroundColor={background_color}
    >
      <Video video={vimeo_url} />
    </ContentBlockWrapper>
  );
};

export default VimeoBlock;
