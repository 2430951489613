import styled from "@emotion/styled";
import React from "react";
import { rem } from "../styling/theme";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)({
  width: "100%",
  height: "100vh",
  margin: 0,
  background: "radial-gradient(#343434, #000)",
});

const Loader = styled.div({
  width: rem(350),
  height: rem(350),
  position: "relative",
  borderRadius: "100%",
  background:
    "linear-gradient(165deg, rgba(0,0,0,1) 0%, rgb(22, 22, 22) 40%, rgb(40, 40, 40) 98%, rgb(240, 240, 240) 100%)",
});

const Item = styled.div({
  ["&:before"]: {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "100%",
    borderBottom: "0 solid #00000005",
    boxShadow: `
      0 -10px 20px 20px #00000040 inset,
      0 -5px 15px 10px #00000050 inset,
      0 -2px 5px #00000080 inset,
      0 -3px 2px #000000BB inset,
      0 2px 0px #000000,
      0 2px 3px #000000,
      0 5px 5px #00000090,
      0 10px 15px #00000060,
      0 10px 20px 20px #00000040`,
    filter: "blur(3px)",
    animation: "2s rotate linear infinite",
  },
});

const FullSizeLoader = () => {
  return (
    <Wrapper flexDirection="column">
      <Loader>
        <Item />
      </Loader>
    </Wrapper>
  );
};

export default FullSizeLoader;
