import styled from "@emotion/styled";
import React, { FC } from "react";
import { useResponsive } from "../../hooks/useResponsive";
import { breakpoints } from "../../styling/theme";
import Background from "../../ui/Background";
import ContentBlockWrapper from "../../ui/ContentBlockWrapper";
import { getCoverUrl } from "../../utils/helpers";
import { SectionRenderT } from "../../utils/types";
import TextGrid, { TextGridWrapper } from "../TextGrid";

const ImageLayer = styled.div<{ url: string }>(({ url }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${url})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left top",
  zIndex: 2,
}));

type RenderBlockT = {
  data: SectionRenderT;
};

const RenderBlock: FC<RenderBlockT> = ({ data }) => {
  const {
    options: { background_color, full_height },
    image_layer,
    render,
    text,
  } = data;

  const { mobileVersion, tabletVersion } = useResponsive(breakpoints.sm);

  return (
    <ContentBlockWrapper
      fullHeight={full_height}
      backgroundColor={background_color}
    >
      {image_layer && (
        <ImageLayer url={image_layer.image.data.attributes.url} />
      )}
      {render && (
        <Background
          position={tabletVersion || mobileVersion ? "relative" : "absolute"}
          url={getCoverUrl(
            tabletVersion || mobileVersion,
            render.desktop,
            render.mobile
          )}
        />
      )}
      {text && (
        <TextGridWrapper>
          <TextGrid items={text} />
        </TextGridWrapper>
      )}
    </ContentBlockWrapper>
  );
};

export default RenderBlock;
