import React, { FC } from "react";
import ContentBlockWrapper from "../../ui/ContentBlockWrapper";
import { SectionTextT } from "../../utils/types";
import TextGrid, { TextGridWrapper } from "../TextGrid";

type TextBlockT = {
  data: SectionTextT;
};

const TextBlock: FC<TextBlockT> = ({ data }) => {
  const {
    options: { background_color, full_height },
    text_blocks,
  } = data;

  return (
    <ContentBlockWrapper
      fullHeight={full_height}
      backgroundColor={background_color}
    >
      {text_blocks && (
        <TextGridWrapper>
          <TextGrid items={text_blocks} />
        </TextGridWrapper>
      )}
    </ContentBlockWrapper>
  );
};

export default TextBlock;
