import styled from "@emotion/styled";
import React, { FC } from "react";
import { rem, theme } from "../../styling/theme";
import ContentBlockWrapper from "../../ui/ContentBlockWrapper";
import FlexBox, { AlignT } from "../../ui/FlexBox";
import { getMediaFileType } from "../../utils/helpers";
import { MediaAlignT, MediaFileTypeE, SectionLoopT } from "../../utils/types";
import TextGrid, { TextGridWrapper } from "../TextGrid";

const PositionWrapper = styled(FlexBox)({
  position: "absolute",
  width: "100%",
  maxWidth: rem(1500),
  zIndex: 1,

  [theme.media.maxSm]: {
    position: "relative",
    maxWidth: "100%",
  },
});

const Content = styled(FlexBox)<{ customWidth: number }>(({ customWidth }) => ({
  width: `${customWidth}%`,
  padding: `${rem(50)} 0`,
  transform: "scale(1)",
  transition: "all 1s cubic-bezier(0.22, 1, 0.36, 1)",

  ["&:hover"]: {
    transform: "scale(1.01);",
  },

  [theme.media.maxSm]: {
    width: "100%",
    padding: `${rem(20)} 0`,
  },
}));

const StyledVideo = styled.video({
  width: "100%",
  maxWidth: "80vw",

  [theme.media.maxSm]: {
    maxWidth: "90%",
    margin: "0 5%",
  },
});

const StyledImage = styled.img({
  width: "100%",
  maxWidth: "80vw",

  [theme.media.maxSm]: {
    maxWidth: "90%",
    margin: "0 5%",
  },
});

type LoopBlockT = {
  data: SectionLoopT;
};

const getAlignToFlexBox = (align: MediaAlignT): AlignT => {
  if (align === "left") {
    return "flex-start";
  }

  if (align === "right") {
    return "flex-end";
  }

  return "center";
};

const LoopBlock: FC<LoopBlockT> = ({ data }) => {
  const {
    options: { background_color, full_height },
    text,
    align,
    width,
    media,
  } = data;

  const url = media.data.attributes.url;
  const mediaType = getMediaFileType(url);

  return (
    <ContentBlockWrapper
      fullHeight={full_height}
      backgroundColor={background_color}
      customMinHeight={rem(700)}
    >
      <PositionWrapper justifyContent={getAlignToFlexBox(align)}>
        <Content customWidth={width}>
          {mediaType === MediaFileTypeE.video ? (
            <StyledVideo
              src={url}
              controls={false}
              autoPlay={true}
              preload={"auto"}
              muted={true}
              loop={true}
              playsInline={true}
            />
          ) : (
            <StyledImage src={url} />
          )}
        </Content>
      </PositionWrapper>
      {text && (
        <TextGridWrapper>
          <TextGrid items={[text]} />
        </TextGridWrapper>
      )}
    </ContentBlockWrapper>
  );
};

export default LoopBlock;
