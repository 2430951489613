import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";

type SectionTextT = {
  element?: "h1" | "h2" | "h3" | "p";
  className?: string;
};

//TODO: Rewrite component to specific usecase
const SectionText: FC<SectionTextT> = ({
  element = "p",
  children,
  className,
}) => {
  const TextElement = element;

  const StyledText = styled(TextElement)({
    position: "absolute",
    margin: 0,
    left: "50%",
    top: "50%",
    fontSize: rem(35),
    fontWeight: 500,
    color: theme.color.primaryColor,
    lineHeight: "normal",
    transition: "all 0.3s cubic-bezier(0.22, 1, 0.36, 1)",
  });

  return <StyledText className={className}>{children}</StyledText>;
};

export default SectionText;
