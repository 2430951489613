import "animate.css";
import React, { FC, useContext, useEffect, useState } from "react";
import Section from "../features/Section";
import FullSizeLoader from "../ui/FullSizeLoader";
import { AppHelperContext } from "../utils/AppHelperProvider";
import { CollectionDataT, SectionT } from "../utils/types";

type SectionsT = {
  toNavigation?: boolean;
  sections?: CollectionDataT<SectionT>;
};

const Sections: FC<SectionsT> = ({ sections, toNavigation = true }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [scrollToSectionId, setScrollToSectionId] = useState<number | null>(
    null
  );
  const { activeSection, setActiveSection } = useContext(AppHelperContext);

  useEffect(() => {
    const slug = window.location.search.substring(1);

    if (slug && sections && sections.data.length > 0) {
      const section = sections.data.find((s) => s.attributes.slug === slug);

      if (section) {
        setScrollToSectionId(section.id);
      }
    }
  }, [sections]);

  useEffect(() => {
    if (!activeSection && !firstLoad) {
      const { pathname, origin } = window.location;

      window.history.pushState("", "", origin + pathname);
    }
  }, [activeSection]);

  useEffect(() => {
    setFirstLoad(false);
  }, [firstLoad]);

  if (sections) {
    const sortedSections = sections.data.sort(
      (a, b) => a.attributes.order - b.attributes.order
    );

    return (
      <>
        {sortedSections.map((item, index) => (
          <Section
            key={item.id}
            item={item}
            isActive={activeSection === item.id}
            setActiveSection={setActiveSection}
            forceScrollToSection={scrollToSectionId === item.id}
          />
        ))}
      </>
    );
  }

  return <FullSizeLoader />;
};

export default Sections;
